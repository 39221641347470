import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { QuestionType } from "types/dataVoteType";
import useVoteState, {
    VoteStateGroupedDeliberation,
    Person,
    Answer,
} from "./useVoteState";
import { Socket } from "socket.io-client";
import getAndFormatAnswers from "../services/getAndFormatAnswers";
import { EVENT } from "types/socketEventsType";
import { getSocket } from "components/socket";

import VoteBasic from "../../common/VoteBasic";
import VoteDistribution from "../../common/VoteDistribution";
import ParticipantButton from "./ParticipantButton";
import ActionSection from "../../common/ActionSection";
import ParticipantHeader from "../../common/ParticipantHeader";

type Props = {
    questions: Array<QuestionType>;
    user: Person;
    proxies: Array<Person>;
    title: string;
    responseFormatVersion: number;
};

declare global {
    interface Window {
        socketClient: Socket;
        constant: { events: { browsers: { ANSWER: string } } };
        currentVote: { responseFormatVersion: number };
    }
}

const emitAsync = (
    event: string,
    ...args: {
        votingDeviceId: string;
        responseFormatVersion: number;
        answers: { questionId: number; answer: string; weight: number }[];
    }[]
) => {
    return new Promise<undefined | { [key: string]: any }>(
        (resolve, reject) => {
            getSocket().emit(event, ...args, (res: any) => {
                if ("SUCCESS" === res.status) {
                    resolve(res.data);
                } else {
                    reject({ code: res.code, message: res.message });
                }
            });
        }
    );
};

const GroupedDeliberation = ({
    questions,
    user,
    proxies,
    title,
    responseFormatVersion,
}: Props) => {
    const [votes, dispatchVoteAction] = useVoteState(questions, proxies, user);
    const i18n = useTranslation();

    const userVote = votes.find(
        (vote) => vote.voterId === user.numTelecoEncrypted
    ) as VoteStateGroupedDeliberation;

    const handleValidate = (voterId: string) => {
        const voteToSend = votes.find(
            (vote) => vote.voterId === voterId
        ) as VoteStateGroupedDeliberation;

        const data = {
            votingDeviceId: voterId,
            responseFormatVersion: responseFormatVersion,
            answers: getAndFormatAnswers(voteToSend),
        };

        dispatchVoteAction({
            type: "sendAnswersRequest",
            voterId: voterId,
        });

        emitAsync(EVENT.ANSWER, data)
            .then(() => {
                dispatchVoteAction({
                    type: "sendAnswersSuccess",
                    voterId: voterId,
                });
            })
            .catch(() => {
                dispatchVoteAction({
                    type: "sendAnswersError",
                    voterId: voterId,
                });
            });
    };

    const convertLineBreak = (sentence: string) => {
        return sentence.split("\n").map((item, key) => {
            return (
                <React.Fragment key={key}>
                    {item}
                    <br />
                </React.Fragment>
            );
        });
    };

    const renderVoteBasic = (
        participant: Person,
        participantVote: VoteStateGroupedDeliberation,
        question: QuestionType,
        index: number
    ) => {
        return (
            <div key={index} aria-label={`deliberation-${question.id}`}>
                <StyledTitle> {convertLineBreak(question.title)}</StyledTitle>
                {participant.weight > 1 ? (
                    <StyledNumberOfVoices>
                        {i18n.t("numberOfVote")}
                        {participant.weight}
                    </StyledNumberOfVoices>
                ) : null}
                <VoteBasic
                    propositions={question.propositions}
                    answers={participantVote.deliberations[index].answers}
                    isSecret={question.isSecret}
                    voteStatus={participantVote.voteStatus}
                    replaceProposal={(propositionId: string) => {
                        dispatchVoteAction({
                            type: "updateWeightVote",
                            idDeliberation: question.id,
                            id: propositionId,
                            voterId: participant.numTelecoEncrypted,
                            weight: participant.weight,
                        });
                    }}
                    proposalsOrientation="horizontal"
                    voterName={participant.displayName}
                />
            </div>
        );
    };

    const renderVoteDistribution = (
        participant: Person,
        participantVote: VoteStateGroupedDeliberation,
        question: QuestionType,
        index: number
    ) => {
        return (
            <div key={index} aria-label={`deliberation-${question.id}`}>
                <StyledTitle> {convertLineBreak(question.title)}</StyledTitle>
                <ParticipantHeader
                    participantIndex={index}
                    participant={participant}
                    participantVote={{
                        answers: participantVote.deliberations[index]
                            .answers as Answer[],
                        voteStatus: participantVote.voteStatus.toString(),
                    }}
                    voteIsWithDistribution={true}
                    isGroupedDeliberation={questions.length > 1}
                />
                <VoteDistribution
                    propositions={question.propositions}
                    answers={participantVote.deliberations[index].answers}
                    isSecret={question.isSecret}
                    voterWeight={participant.weight}
                    voteStatus={participantVote.voteStatus}
                    updateWeight={(propositionId: string, weight: number) => {
                        dispatchVoteAction({
                            type: "updateWeightVote",
                            idDeliberation: question.id,
                            id: propositionId,
                            voterId: participant.numTelecoEncrypted,
                            weight,
                        });
                    }}
                    proposalsOrientation="horizontal"
                />
            </div>
        );
    };

    const renderDeliberations = (
        person: Person,
        stateVote: VoteStateGroupedDeliberation
    ) => {
        return (
            <>
                {questions.map((question, index) => {
                    if (
                        question.authorizeWeightDistribution &&
                        1 !== person.weight
                    ) {
                        return renderVoteDistribution(
                            person,
                            stateVote,
                            question,
                            index
                        );
                    }
                    // No proxies + no weight distribution or plurinominal
                    return renderVoteBasic(person, stateVote, question, index);
                })}

                <ActionSection
                    validateVote={() =>
                        handleValidate(person.numTelecoEncrypted)
                    }
                    resetVote={() =>
                        dispatchVoteAction({
                            type: "resetVote",
                            voterId: stateVote.voterId,
                        })
                    }
                    voteStatus={stateVote.voteStatus}
                />
            </>
        );
    };

    const renderVote = () => {
        // Without proxies
        if (0 === proxies.length) {
            return <>{renderDeliberations(user, userVote)}</>;
        }

        //With proxies
        return (
            <>
                <ParticipantButton
                    voteStatus={userVote.voteStatus}
                    voterWeight={user.weight}
                    displayName={user.displayName}
                >
                    {renderDeliberations(user, userVote)}
                </ParticipantButton>

                <p>{i18n.t("proxy")}</p>
                {proxies.map((proxy, index) => {
                    const proxyVote = votes.find(
                        (vote) => vote.voterId === proxy.numTelecoEncrypted
                    ) as VoteStateGroupedDeliberation;

                    return (
                        <ParticipantButton
                            voteStatus={proxyVote.voteStatus}
                            voterWeight={proxy.weight}
                            displayName={proxy.displayName}
                            key={index}
                        >
                            {renderDeliberations(proxy, proxyVote)}
                        </ParticipantButton>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <Wrapper>
                <WrapperVote>
                    <StyledGlobalTitle>{title}</StyledGlobalTitle>
                    {renderVote()}
                </WrapperVote>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const WrapperVote = styled.div`
    margin: auto;
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const StyledGlobalTitle = styled.div`
    margin-bottom: 1.5rem;
    font-size: 14px;
`;

const StyledTitle = styled.div`
    font-size: 1.2rem;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
`;

const StyledNumberOfVoices = styled.div`
    color: #626262;
    padding-bottom: 0.5rem;
    font-size: 14px;
`;

export default GroupedDeliberation;
