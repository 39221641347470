import { useEffect, useRef } from "react";
import SignaturePad from "signature_pad";
import styled from "styled-components";

import { CSS_VARIABLES } from "enums/cssVariables";
import { useTranslation } from "react-i18next";

type Props = {
    setSignatureData: (data: string | null) => void;
};

const Signature = ({ setSignatureData }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const signaturePadRef = useRef<SignaturePad>();

    const i18n = useTranslation();

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            canvas.width = 250;
            canvas.height = 150;
            signaturePadRef.current = new SignaturePad(canvas, {
                backgroundColor: "#efefef",
                minWidth: 1,
                maxWidth: 2,
            });
        }
    }, []);

    const handleClear = () => {
        if (!signaturePadRef.current?.isEmpty()) {
            signaturePadRef.current?.clear();
            setSignatureData(null);
        }
    };

    const handleSave = () => {
        if (!signaturePadRef.current?.isEmpty()) {
            setSignatureData(signaturePadRef.current?.toDataURL() ?? null);
        }
    };

    return (
        <Wrapper>
            <StyledCanvas ref={canvasRef} />
            <StyledButton onClick={handleClear}>{`${i18n.t(
                "signature.clear"
            )}`}</StyledButton>
            <StyledSaveButton onClick={handleSave}>{`${i18n.t(
                "signature.save"
            )}`}</StyledSaveButton>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin: 1rem 0;
`;

const StyledCanvas = styled.canvas`
    cursor: crosshair;
`;

const StyledButton = styled.button.attrs({ type: "button" })`
    padding: 6px 12px;
    margin: 0.5rem 0.5rem;
    font-size: 14px;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;

const StyledSaveButton = styled(StyledButton)`
    background-color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: #fff;

    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;
export default Signature;
