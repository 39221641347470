import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { io as Client, Socket } from "socket.io-client";

const BROWSER_NAMESPACE = "/socket/browser";

let socket: Socket;

type SocketOptions = {
    transports: string[];
    reconnectionAttempts: number;
    forceNew: boolean;
    rejectUnauthorized: false;
    query: {
        codeOnline: string;
    };
    withCredentials: boolean;
    auth:
        | {
              [key: string]: any;
          }
        | ((cb: (data: object) => void) => void);
};

const buildOptionSocket = (codeOnline: string): SocketOptions => ({
    transports: ["websocket"],
    reconnectionAttempts: 1,
    forceNew: true,
    rejectUnauthorized: false,
    query: {
        codeOnline,
    },
    withCredentials: true,
    auth: (cb) => {
        cb({
            token: `Bearer ${localStorage.getItem(LS_AUTH_TOKEN_KEY)}`,
        });
    },
});

export const buildSocket = (codeOnline: string) => {
    if (socket !== undefined && socket !== null) {
        return;
    }

    socket = Client(
        `${process.env.REACT_APP_ONLINE_API_BASE_URL}${BROWSER_NAMESPACE}`,
        buildOptionSocket(codeOnline)
    );
};

export const getSocket = () => socket;
