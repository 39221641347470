import { CSS_VARIABLES } from "enums/cssVariables";
import styled from "styled-components";

const Header = () => {
    return (
        <HeaderWrapper>
            <LogoWrapper>
                <Logo alt="Logo" />
            </LogoWrapper>
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.header`
    padding: 1.3rem 0;
    display: flex;
    justify-content: center;
`;

const LogoWrapper = styled.header`
    width: 320px;
    text-align: var(${CSS_VARIABLES.LOGO_ALIGNMENT});
`;

const Logo = styled.img`
    content: var(${CSS_VARIABLES.LOGO_URL});
    max-height: var(${CSS_VARIABLES.LOGO_MAX_HEIGHT});
    height: auto;
`;

export default Header;
