import React, { useState, useContext, createContext, useEffect } from "react";
import { SessionType } from "../../types/sessionType";

type SessionContextType = {
    session: SessionType | null;
    setSession: React.Dispatch<React.SetStateAction<SessionType | null>>;
};

type SessionContextProviderProps = {
    children: React.ReactNode;
};

const SessionContext = createContext<SessionContextType>({
    session: null,
    setSession: () => {},
});

export const useSession = () => useContext(SessionContext);

export const SessionContextProvider = ({
    children,
}: SessionContextProviderProps) => {
    const [session, setSession] = useState<SessionType | null>(() => {
        const localSession = localStorage.getItem("session");
        return localSession ? JSON.parse(localSession) : null;
    });

    useEffect(() => {
        localStorage.setItem("session", JSON.stringify(session));
    }, [session]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
};
