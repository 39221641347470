import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useUser } from "../../helper/userContext";
import { useSession } from "components/helper/sessionContext";
import packageJson from "../../../../package.json";
import LanguageSelector from "../LanguageSelector";

import SessionInformation from "./SessionInformation";

const Footer = () => {
    const i18n = useTranslation();
    const userContext = useUser();
    const sessionContext = useSession();
    const codeOnline = sessionContext.session?.codeOnline;

    return (
        <WrapperFooter>
            {userContext.user && codeOnline ? (
                <SessionInformation
                    codeOnline={codeOnline}
                    user={userContext.user}
                ></SessionInformation>
            ) : null}
            <WrapperInformation>
                <a href={process.env.REACT_APP_HOME}>
                    {process.env.REACT_APP_THEME}
                </a>
                <span>{packageJson.version}</span>
                <span>-</span>
                <a href={process.env.REACT_APP_PRIVACY_LINK}>{`${i18n.t(
                    "privacyPolicyText"
                )}`}</a>
                <span>-</span>
                <LanguageSelector />
            </WrapperInformation>
        </WrapperFooter>
    );
};

const WrapperFooter = styled.footer`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.7rem;
    margin-top: auto;
    padding-bottom: 1rem;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const WrapperInformation = styled.div`
    display: flex;
    align-items: center;
    padding-left: 0.3rem;
    & * {
        padding: 0.13rem;
        color: #333;
    }
`;

export default Footer;
