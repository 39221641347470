import { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { getLogInInformation } from "components/helper/callApi";
import { DisplayedError, NotFoundError, UnknownError } from "services/errors";
import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";
import { Scenario } from "enums/scenario";
import { SessionType } from "types/sessionType";
import { CSS_VARIABLES } from "enums/cssVariables";
import { ERROR } from "types/snackBar.constant";

import LoginPassword from "./authentificationMode/LoginPassword";
import LoginEmailPasswordSMS from "./authentificationMode/LoginEmailPasswordSMS";
import BasicForm from "./authentificationMode/common/BasicForm";
import UserAnonymousLogin from "./authentificationMode/UserAnonymousLogin";
import NameFirstNameSignature from "./authentificationMode/NameFirstNameSignature";
import Layout from "./common/Layout";
import Spinner from "./common/Spinner";
import ErrorPage from "./common/ErrorPage";
import InstallButtonWrapper from "./InstallButtonWrapper";
import PinSignature from "./authentificationMode/PinSignature";

type LogInInformation = Pick<
    SessionType,
    "codeOnline" | "scenario" | "sessionName"
>;

const UserLoginPage = () => {
    const [logInInformation, setLogInInformation] =
        useState<LogInInformation | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<DisplayedError | null>(null);

    const i18n = useTranslation();
    const { onlineCode } = useParams();
    const { user } = useUser();
    const { session, setSession } = useSession();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getLogInInformation(onlineCode!)
            .then((res) => {
                if (res.ok) {
                    res.json().then((data) => {
                        setLogInInformation(data);
                        setSession(data as unknown as SessionType);
                    });
                    setIsLoading(false);
                } else {
                    res.status === 404
                        ? setError(new NotFoundError())
                        : setError(new UnknownError());
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setError(new UnknownError());
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineCode]);

    const handleError = (error: NotFoundError | UnknownError) => {
        setError(error);
    };

    const renderScenario = (scenario: string) => {
        switch (scenario) {
            case Scenario.SANS_SCENARIO:
                return <UserAnonymousLogin codeOnline={onlineCode as string} />;
            case Scenario.EMAIL_PASSWORD:
                return (
                    <LoginPassword
                        codeOnline={onlineCode as string}
                        sessionName={logInInformation?.sessionName as string}
                    />
                );
            case Scenario.EMAIL_PASSWORD_SMS:
                return (
                    <LoginEmailPasswordSMS
                        codeOnline={onlineCode as string}
                        sessionName={logInInformation?.sessionName as string}
                    />
                );
            case Scenario.EQUIPE:
                return (
                    <BasicForm
                        fields={[
                            {
                                name: "equipe",
                                placeholder: `${i18n.t("team")}(*)`,
                                ariaLabel: `${i18n.t("team")}(*)`,
                            },
                        ]}
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/equipe"}
                        onError={handleError}
                    />
                );
            case Scenario.PIN:
                return (
                    <BasicForm
                        fields={[
                            {
                                name: "idReference",
                                placeholder: `${i18n.t("pinCode")}`,
                                ariaLabel: `${i18n.t("pinCode")}`,
                            },
                        ]}
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/reference_multi_devices"}
                        onError={handleError}
                    />
                );
            case Scenario.PIN_SIGNATURE:
                return (
                    <PinSignature
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/reference_multi_devices"}
                        onError={handleError}
                    />
                );
            case Scenario.PSEUDO:
                return (
                    <BasicForm
                        fields={[
                            {
                                name: "pseudo",
                                placeholder: `${i18n.t("pseudo")}(*)`,
                                ariaLabel: `${i18n.t("pseudo")}(*)`,
                            },
                        ]}
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/pseudo"}
                        onError={handleError}
                    />
                );
            case Scenario.PSEUDO_EMAIL:
                return (
                    <BasicForm
                        fields={[
                            {
                                name: "pseudo",
                                placeholder: `${i18n.t("pseudo")}(*)`,
                                ariaLabel: `${i18n.t("pseudo")}(*)`,
                            },
                            {
                                name: "email",
                                placeholder: `${i18n.t("email")}(*)`,
                                ariaLabel: `${i18n.t("email")}(*)`,
                            },
                        ]}
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/pseudo_email"}
                        onError={handleError}
                    />
                );
            case Scenario.NOM_PRENOM:
                return (
                    <BasicForm
                        fields={[
                            {
                                name: "nom",
                                placeholder: `${i18n.t("name")}(*)`,
                                ariaLabel: `${i18n.t("name")}(*)`,
                            },
                            {
                                name: "prenom",
                                placeholder: `${i18n.t("firstName")}(*)`,
                                ariaLabel: `${i18n.t("firstName")}(*)`,
                            },
                        ]}
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/nom_prenom"}
                        onError={handleError}
                    />
                );
            case Scenario.NOM_PRENOM_SIGNATURE:
                return (
                    <NameFirstNameSignature
                        fields={[
                            {
                                name: "nom",
                                placeholder: `${i18n.t("name")}(*)`,
                                ariaLabel: `${i18n.t("name")}(*)`,
                            },
                            {
                                name: "prenom",
                                placeholder: `${i18n.t("firstName")}(*)`,
                                ariaLabel: `${i18n.t("firstName")}(*)`,
                            },
                        ]}
                        action={"/form/scenario/nom_prenom"}
                        codeOnline={onlineCode as string}
                        onError={handleError}
                    />
                );
            case Scenario.NOM_PRENOM_EMAIL:
                return (
                    <BasicForm
                        fields={[
                            {
                                name: "nom",
                                placeholder: `${i18n.t("name")}(*)`,
                                ariaLabel: `${i18n.t("name")}(*)`,
                            },
                            {
                                name: "prenom",
                                placeholder: `${i18n.t("firstName")}(*)`,
                                ariaLabel: `${i18n.t("firstName")}(*)`,
                            },
                            {
                                name: "email",
                                placeholder: `${i18n.t("email")}(*)`,
                                ariaLabel: `${i18n.t("email")}(*)`,
                            },
                        ]}
                        codeOnline={onlineCode as string}
                        action={"/form/scenario/nom_prenom_email"}
                        onError={handleError}
                    />
                );
        }
    };

    if (error) {
        return <ErrorPage error={error} />;
    }

    if (user && session) {
        return <Navigate replace to={`/session/${onlineCode}`} />;
    }

    return (
        <>
            <InstallButtonWrapper />
            <Layout>
                <Wrapper>
                    <FormWrapper>
                        <FormTitle>
                            {logInInformation?.sessionName ||
                                i18n.t(
                                    `dynamicSessionTitle.${process.env.REACT_APP_THEME}`,
                                    { onlineCode }
                                )}
                        </FormTitle>

                        {isLoading ? (
                            <SpinnerWrapper>
                                <Spinner />
                            </SpinnerWrapper>
                        ) : (
                            renderScenario(logInInformation?.scenario as string)
                        )}
                    </FormWrapper>
                    <p>
                        {i18n.t("loginReassuranceText", {
                            AppName: process.env.REACT_APP_THEME,
                        })}
                        <a href={process.env.REACT_APP_PRIVACY_LINK}>
                            {`${i18n.t("loginReassuranceLinkText")}`}
                        </a>
                    </p>
                </Wrapper>
            </Layout>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > p {
        margin: auto;
        padding: 2rem 0 1rem 0;
        font-size: 0.75rem;
        line-height: 22px;
        text-align: left;
        width: 620px;
        > a {
            text-decoration: underline;
            color: black;
        }
        @media (max-width: 768px) {
            width: 320px;
        }
    }
`;

const FormWrapper = styled.div`
    text-align: center;
    box-shadow: var(${CSS_VARIABLES.BOX_SHADOW_PIXEL})
        var(${CSS_VARIABLES.BORDER_FORM_COLOR});
    width: 320px;
    background-color: #fafafa;
`;

const FormTitle = styled.div`
    font-size: 1.3rem;
    padding: 1.25rem 1rem;
    color: var(${CSS_VARIABLES.PRIMARY_TEXT_COLOR});
    background-color: var(${CSS_VARIABLES.BACKGROUND_FORM_COLOR});
`;

const SpinnerWrapper = styled.div`
    padding-bottom: 20px;
`;

export default UserLoginPage;
