import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
    loginFormScenario,
    getCurrentUser,
    getSessionInformation,
    performSignature,
} from "components/helper/callApi";
import { useSession } from "components/helper/sessionContext";
import { useUser } from "components/helper/userContext";
import { NotFoundError, UnknownError } from "services/errors";
import { User } from "types/User";
import { SessionType } from "types/sessionType";
import { useHandleErrors } from "./common/handleErrors";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";

import { ValidateButton } from "components/common/ValidateButton";
import Form from "./common/Form";
import Signature from "./common/Signature";

type Props = {
    codeOnline: string;
    action: string;
    onError: (error: NotFoundError | UnknownError) => void;
};

const PinSignature = ({ codeOnline, action, onError }: Props) => {
    const { setUser } = useUser();
    const { setSession } = useSession();

    const [signatureData, setSignatureData] = useState<string | null>(null);
    const [localUser, setLocalUser] = useState<User | null>(null);
    const [localSessionInfos, setLocalSessionInfos] =
        useState<SessionType | null>(null);

    const i18n = useTranslation();

    const { handleLoginError, handleCurrentUserError, handleSessionError } =
        useHandleErrors();

    const onSubmitFormPin = async (data: any) => {
        try {
            const loginResponse = await loginFormScenario(
                codeOnline,
                action,
                data
            );

            if (!loginResponse.ok) {
                handleLoginError(loginResponse.status);

                return;
            }

            const response = await loginResponse.json();

            if (response.token) {
                localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);
            }

            const currentUserResponse = await getCurrentUser(["signature"]);

            if (currentUserResponse.ok) {
                const userData = await currentUserResponse.json();
                setLocalUser(userData);
                const sessionResponse = await getSessionInformation(codeOnline);

                if (sessionResponse.ok) {
                    const sessionData = await sessionResponse.json();
                    setLocalSessionInfos(sessionData);
                } else {
                    handleSessionError(sessionResponse.status);
                }
            } else {
                handleCurrentUserError(currentUserResponse.status);
            }
        } catch (error) {
            onError(new UnknownError());
        }
    };

    const handleSignature = async () => {
        try {
            const sendSignature = await performSignature({
                signature: signatureData as string,
            });

            if (sendSignature.ok) {
                const refreshedUser = await sendSignature.json();
                setLocalUser(refreshedUser);
            } else {
                handleLoginError(sendSignature.status);
            }
        } catch (error) {
            onError(new UnknownError());
        }
    };

    if (localUser && localUser.signature) {
        //We update the user and the session context to trigger the redirection
        //(located in UserLoginPage)
        setUser(localUser);
        setSession(localSessionInfos);
    }

    if (localSessionInfos && localUser && !localUser.signature) {
        return (
            <Wrapper>
                <Signature setSignatureData={setSignatureData} />
                <StyledButton
                    onClick={handleSignature}
                    disabled={signatureData === null}
                >
                    {`${i18n.t("validate")}`}
                </StyledButton>
            </Wrapper>
        );
    }

    return (
        <Form
            fields={[
                {
                    name: "idReference",
                    placeholder: `${i18n.t("pinCode")}`,
                    ariaLabel: `${i18n.t("pinCode")}`,
                },
            ]}
            onSubmit={onSubmitFormPin}
            formMessage={i18n.t(`enterPin.${process.env.REACT_APP_THEME}`)}
            formButtonText={i18n.t("joinIn")}
        />
    );
};

const Wrapper = styled.div`
    padding: 1.5rem;
`;

const StyledButton = styled(ValidateButton)``;

export default PinSignature;
