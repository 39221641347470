import { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NotFoundError, UnknownError } from "services/errors";
import { LoginStatus } from "types/voteEnum";
import { loginFormScenario, performSignature } from "components/helper/callApi";
import { useHandleErrors } from "./common/handleErrors";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { useUser } from "components/helper/userContext";

import Signature from "./common/Signature";
import { Input } from "components/common/Input";
import { ValidateButton } from "components/common/ValidateButton";

type Props = {
    fields: Array<{
        name: string;
        placeholder: string;
        ariaLabel: string;
    }>;
    codeOnline: string;
    action: string;
    onError: (error: NotFoundError | UnknownError) => void;
};

const NameFirstNameSignature = ({
    fields,
    codeOnline,
    action,
    onError,
}: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const [loginStatus, setLoginStatus] = useState(LoginStatus.Idle);
    const [signatureData, setSignatureData] = useState<string | null>(null);

    const userContext = useUser();

    const i18n = useTranslation();

    const { handleLoginError } = useHandleErrors();

    const onSubmit = async (data: any) => {
        try {
            const loginResponse = await loginFormScenario(
                codeOnline,
                action,
                data
            );

            if (!loginResponse.ok) {
                handleLoginError(loginResponse.status);
                return;
            }

            const response = await loginResponse.json();

            if (response.token) {
                localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);
            }

            const sendSignature = await performSignature({
                signature: signatureData as string,
            });

            if (sendSignature.ok) {
                const userData = await sendSignature.json();
                userContext.setUser(userData);
            } else {
                handleLoginError(sendSignature.status);
            }
        } catch (error) {
            onError(new UnknownError());
        }
    };

    const watchedValues = watch(["nom", "prenom"]);

    useEffect(() => {
        const [nom, prenom] = watchedValues;
        nom && prenom && signatureData
            ? setLoginStatus(LoginStatus.PendingValidation)
            : setLoginStatus(LoginStatus.Idle);
    }, [signatureData, watchedValues]);

    return (
        <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => (
                    <StyledInput
                        {...register(field.name, { required: true })}
                        type={"text"}
                        error={Boolean(errors[field.name])}
                        placeholder={field.placeholder}
                        aria-invalid={errors[field.name] ? "true" : "false"}
                        key={index}
                    />
                ))}
                <Signature setSignatureData={setSignatureData} />
                <StyledButton
                    type="submit"
                    disabled={loginStatus === LoginStatus.Idle}
                >
                    {`${i18n.t("joinIn")}`}
                </StyledButton>
            </form>
        </FormWrapper>
    );
};

const StyledInput = styled(Input)<{ error: boolean }>`
    &:focus {
        border-color: ${(props) => (props.error ? " #dc3545" : null)};
        box-shadow: ${(props) =>
            props.error ? " 0 0 0 0.2rem rgb(220 53 69 / 25%);" : null};
    }
    border-color: ${(props) => (props.error ? " #dc3545" : null)};
    box-shadow: ${(props) =>
        props.error ? " 0 0 0 0.2rem rgb(220 53 69 / 25%);" : null};
`;

const FormWrapper = styled.div`
    padding: 1.5rem;
`;

const StyledButton = styled(ValidateButton)``;

export default NameFirstNameSignature;
