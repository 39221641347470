import { useEffect, useState } from "react";
import styled from "styled-components";

import { buildSocket, getSocket } from "../../socket";
import { EVENT } from "types/socketEventsType";
import { User } from "types/User";

import LogoutButton from "./LogoutButton";

interface StyledDivProps {
    sessionIsActive: boolean;
}

type Props = { codeOnline: string; user: User };

const WrapperSessionInformation = ({ codeOnline, user }: Props) => {
    const [sessionIsActive, setSessionIsActive] = useState<boolean>(false);

    useEffect(() => {
        const cleanUpHandler = connectWebsocketToServer();
        return cleanUpHandler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeOnline]);

    const connectWebsocketToServer = () => {
        buildSocket(codeOnline);
        const socket = getSocket();

        socket.on(EVENT.CONNECT, () => {
            setSessionIsActive(true);
        });
        socket.on(EVENT.DISCONNECT, () => {
            setSessionIsActive(false);
        });
        socket.on(EVENT.SESSION_CHANGE_STATUS, (isActive: boolean) => {
            setSessionIsActive(isActive);
        });

        return () => {
            if (socket.connected) {
                console.log("disconnect socket...");
                socket.disconnect();
            }
        };
    };

    return (
        <>
            <StyledWrapper>
                <div>
                    <SquareWrapper sessionIsActive={sessionIsActive} />
                    <SessionNumber>
                        {codeOnline} - n°
                        <span>{user.remoteNumber}</span>
                    </SessionNumber>
                </div>
                <LogoutButton />
            </StyledWrapper>
        </>
    );
};

const SquareWrapper = styled.div<StyledDivProps>`
    margin: 1px;
    width: 1.25rem;
    background-color: ${(props) =>
        props.sessionIsActive ? " #60b860" : "#607D91"};
`;

const SessionNumber = styled.div`
    padding: 0.25rem;
    font-size: 0.75rem;
`;

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.25rem;
    > div {
        border: 1px solid #ccc;
        margin-right: 1rem;
        display: flex;
        height: 100%;
    }
    @media (min-width: 768px) {
        padding-bottom: 0;
    }
`;

export default WrapperSessionInformation;
