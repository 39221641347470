import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import styled from "styled-components";

import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { useSession } from "components/helper/sessionContext";
import { logOut } from "components/helper/callApi";
import { useUser } from "components/helper/userContext";

import { BasicButton } from "components/common/BasicButton";

const ERROR = "error";

const LogoutButton = () => {
    const { setUser } = useUser();
    const { session } = useSession();
    const navigate = useNavigate();
    const i18n = useTranslation();

    const handleLogOut = () => {
        logOut()
            .then((res: any) => {
                if (res.ok) {
                    setUser(null);
                    localStorage.removeItem(LS_AUTH_TOKEN_KEY);
                    session &&
                        navigate(`/login/${session.codeOnline}`, {
                            replace: true,
                        });
                } else {
                    enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                        variant: ERROR,
                    });
                }
            })
            .catch((e: any) => {
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            });
    };

    return (
        <LogOutBtn onClick={handleLogOut}>{`${i18n.t("logOut")}`}</LogOutBtn>
    );
};

const LogOutBtn = styled(BasicButton)`
    padding: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

export default LogoutButton;
