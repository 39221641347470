export type SessionType = {
    codeOnline: string;
    scenario: string;
    sessionName: string;
    displayMode: string;
    lang: string;
    telecommandeParticipantCSS: string;
};

export enum DisplayMode {
    QUESTION = "question",
    VIRTUAL_REMOTE = "virtualRemote",
}
